.history {

  &__info {
    max-width: 659px;
    margin: 0 auto 128px;
    text-align: center;

    @media (max-width: 1246px) {
      margin-bottom: 50px;
    }
  }

  &__list {
    @media (max-width: 1246px) {
      display: flex;
      justify-content: space-around;
      ;
      flex-wrap: wrap;
      gap: 40px;
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--color-dark);

      @media (max-width: 1246px) {
        content: none;
      }
    }

    &:not(:last-child) {
      padding-bottom: 88px;

      @media (max-width: 1246px) {
        padding-bottom: 50px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 100%;
        background-color: var(--color-dark);

        @media (max-width: 1246px) {
          content: none;
        }
      }
    }

    &:nth-child(2) {
      text-align: right;

      @media (max-width: 1246px) {
        text-align: left;
      }

      .history__img {
        order: 1;

        @media (max-width: 1246px) {
          order: 0;
        }
      }
    }

    @media (max-width: 1246px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: calc(50% - 40px / 2);
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__img {
    object-fit: cover;
    border-radius: 24px;

    @media (max-width: 1246px) {
      width: 100%;
      margin-bottom: 20px;
      height: 250px;
    }
  }

  &__item-info {
    max-width: 517px;
    width: 100%;

    @media (max-width: 1246px) {
      max-width: none;
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    font-size: 40px;
    color: var(--color-dark);
    line-height: normal;

    @media (max-width: 1246px) {
      font-size: 25px;
    }
  }

}