.card {
  height: 100%;
  padding: 24px;
  background-color: var(--color-white);
  border-radius: 24px;
  text-align: left;

  &__box {
    position: relative;
    z-index: 1;
  }

  img {
    width: 100%;
    border-radius: 16px;
    object-fit: cover;

    @media (max-width: 576px) {
      height: 250px;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    font-weight: var(--fw-600);
    line-height: normal;
    color: var(--color-dark);
    margin-bottom: 8px;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &--blog {
    .card__box {
      &::before {
        content: "";
        position: absolute;
        right: 24px;
        bottom: 24px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: var(--color-blue-800);
        background-image: url("../images/icons/arrow-diagonal.svg");
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 576px) {
          right: 12px;
          bottom: 12px;
        }
      }
    }

    img {
      margin-bottom: 24px;
    }
  }


  &--product {
    .card__price {
      position: absolute;
      bottom: 24px;
      right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 143px;
      padding: 12px 24px;
      text-align: center;
      border-radius: 24px;
      background-color: var(--color-white);
      font-weight: var(--fw-600);
      color: var(--color-dark);

      @media (max-width: 576px) {
        min-width: auto;
        right: 12px;
        bottom: 12px;
        font-size: 12px;
      }
    }


    img {
      margin-bottom: 48px;
    }
  }
}