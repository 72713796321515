.policy {

  &__content {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  h2 {
    font-weight: var(--fw-700);

    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  ul {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  li {
    padding-left: 5px;

    &::marker {
      content: '-';
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-blue-900);
  }

  b,
  strong {
    font-weight: var(--fw-600);
  }
}