.faq {
  padding: 176px 0;

  @media (max-width: 1246px) {
    padding: 80px 0;
  }

  &__item {
    border-radius: 32px;
    background-color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__spoiler {
    position: relative;
    z-index: 1;
    padding-left: 24px;

    &::before {
      content: "";
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: 32px;
      height: 32px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../images/icons/arrow-spoiler.svg");
      transition: transform 0.3s;
    }

    &[open]::before {
      transform: translateY(-50%) rotate(0);
    }
  }


  &__subtitle {
    padding: 24px 0;
    margin-bottom: 0;
    cursor: pointer;
    padding-right: 80px;

    @media (max-width: 992px) {
      font-size: 18px;
    }
  }

  &__answer {
    padding-bottom: 24px;
    padding-right: 80px;
  }
}