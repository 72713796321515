@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  &__list {
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
      margin-bottom: 15px;
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 32px;

      @media (max-width: 1246px) {
        margin-right: 15px;
      }
    }

    @media (max-width: 992px) {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  &__link {
    display: block;
    color: var(--color-dark);
    transition: box-shadow 0.3s;
    border-radius: 5px;
    line-height: normal;
    text-decoration: underline transparent;
    transition: box-shadow 0.3s, text-decoration-color 0.3s, opacity 0.3s, text-shadow 0.3s;
    text-wrap: nowrap;

    @media (max-width: 992px) {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
    }


    &.true {
      padding: 16px 24px;
      border-radius: 32px;
      background-color: var(--color-white);

      @media (max-width: 992px) {
        padding: 20px 0;
        font-size: 20px;
        border-radius: 0;
        background-color: transparent;
        font-weight: var(--fw-700);
      }

      @media (max-width: 992px) and (orientation: landscape) {
        padding: 7px 0;
        font-size: 16px;
      }
    }
  }
}