/* stylelint-disable no-unknown-animations */
@use "../utils/functions/" as*;
@use "../utils/mixins/" as*;

.burger {
  position: relative;
  z-index: 15;
  width: 44px;
  height: 44px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: var(--color-dark);
  transition: box-shadow 0.3s, background-color 0.2s;

  @media (max-width: 992px) {
    display: flex;

  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background-color: currentColor;
  }

  &::before {
    top: 11px;
    transition: top 0.15s ease-in, transform 0.15s ease-in;
    transition-delay: 0.15s, 0s;
  }

  &::after {
    bottom: 11px;
    transition: bottom 0.15s ease-in, transform 0.15s ease-in;
    transition-delay: 0.15s, 0s;
  }

  &__line {
    width: 30px;
    height: 2px;
    background-color: currentColor;
    transition: transform 0.15s ease-in;
  }

  &.active {
    &::before {
      top: 21px;
      transform: translateX(-50%) rotate(45deg);
      transition-delay: 0s, 0.15s;
    }

    &::after {
      bottom: 21px;
      transform: translateX(-50%) rotate(-45deg);
      transition-delay: 0s, 0.15s;
    }

    .burger__line {
      transform: scaleX(0);
    }
  }
}