.benefits {

  @media (max-width: 576px) {
    text-align: center;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;

    @media (max-width: 1246px) {
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 24px;
    }
  }

  &__item {
    max-width: 390px;
    width: 100%;
    padding: 24px;
    border-radius: 24px;
    background-color: var(--color-white);

    &::before {
      content: "";
      display: block;
      width: 80px;
      height: 80px;
      margin: 0 auto 32px;
      border-radius: 50%;
      background-color: var(--color-grey-800);
      background-position: center;
      background-repeat: no-repeat;
    }

    &--check {
      &::before {
        background-image: url("../images/icons/check.svg");
      }
    }

    &--star {
      &::before {
        background-image: url("../images/icons/star.svg");
      }
    }

    &--technology {
      &::before {
        background-image: url("../images/icons/technology.svg");
      }
    }
  }
}