.custom {
  color: var(--color-grey-850);

  &__inner {
    min-height: 652px;
    padding: 48px;
    padding-top: 128px;
    border-radius: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: stretch;
  }

  &__content {
    max-width: 548px;
  }

  &__title {
    margin-bottom: 16px;
    color: var(--color-white);
  }

  &__text {
    margin-bottom: 48px;
  }
}