@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-dark: #161616;
  --color-grey-900: #75717a;
  --color-grey-850: #c6c6c6;
  --color-grey-800: #eceef2;
  --color-grey-800-50: #eceef27c;
  --color-grey-700: #bab8bd;
  --color-blue-900: #51647d;
  --color-blue-800: #d5dfed;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

body.lock {
  overflow: hidden;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-size: 16px;
  font-weight: var(--fw-500);
  color: var(--color-grey-900);
  background-color: var(--color-grey-800);
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.background {
  background-color: var(--color-blue-400);
}

.page.open {
  overflow: hidden;
}

.container {
  max-width: var(--container-width);
  width: 100%;
  padding: 0 var(--container-offset);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: calc(var(--header-height)  + 8px);
}

@media (max-width: 992px) {
  .main {
    padding-top: var(--header-height);
  }
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient);
  position: absolute;
  inset: 0;
}

.background {
  background-color: var(--color-blue-100);
}

.background--dark {
  background-color: var(--color-blue-400);
}

.logo {
  z-index: 15;
  border-radius: 5px;
  transition: box-shadow .3s, opacity .3s;
  display: block;
  position: relative;
}

@media (max-width: 576px) {
  .logo {
    max-width: 200px;
  }
}

.hero-inner {
  min-height: 652px;
  background-color: var(--color-white);
  border-radius: 24px;
  align-items: center;
  padding: 24px;
  display: flex;
}

@media (max-width: 1246px) {
  .hero-inner {
    border-radius: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 992px) {
  .hero-inner {
    min-height: 450px;
  }
}

@media (max-width: 576px) {
  .hero-inner {
    min-height: auto;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .hero-inner {
    min-height: auto;
  }
}

.section {
  padding: 88px 0;
}

@media (max-width: 1246px) {
  .section {
    padding: 40px 0;
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.title {
  font-size: 48px;
  font-weight: var(--fw-600);
  color: var(--color-dark);
  margin-bottom: 64px;
  line-height: normal;
}

@media (max-width: 992px) {
  .title {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 25px;
  }
}

.subtitle {
  font-size: 24px;
  font-weight: var(--fw-600);
  color: var(--color-dark);
  margin-bottom: 8px;
  line-height: normal;
}

@media (max-width: 576px) {
  .subtitle {
    font-size: 20px;
  }
}

.slogan {
  font-size: 20px;
  font-weight: var(--fw-400);
  color: var(--color-grey-900);
  margin-bottom: 8px;
  line-height: normal;
  display: block;
}

@media (max-width: 576px) {
  .slogan {
    font-size: 18px;
  }
}

.header {
  z-index: 10;
  width: 100%;
  padding: 24px 0;
  transition: transform .15s, padding .15s, background-color .3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header:before {
  content: "";
  z-index: 11;
  width: 100vw;
  height: 100%;
  background-color: var(--color-grey-800);
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 992px) {
  .header {
    padding: 12px 0;
  }
}

.header__container {
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  display: flex;
}

.header__logo {
  align-self: flex-start;
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.header__inner {
  z-index: 11;
  position: relative;
}

@media (max-width: 992px) {
  .header__inner {
    z-index: 10;
    top: var(--header-height);
    width: 50%;
    min-width: 400px;
    height: calc(100% - var(--header-height));
    background-color: var(--color-white);
    transition: transform .3s;
    position: fixed;
    right: 0;
    transform: translateX(100%);
  }

  .header__inner.active {
    transform: translateX(0);
  }
}

@media (max-width: 576px) {
  .header__inner {
    width: 100%;
    min-width: 100%;
  }
}

.user-btn {
  align-items: center;
  column-gap: 8px;
  display: flex;
}

.user-btn--desktop {
  z-index: 11;
  position: relative;
}

@media (max-width: 992px) {
  .user-btn--desktop {
    display: none;
  }
}

.user-btn--mobile {
  padding: 0 15px;
  display: none;
}

@media (max-width: 992px) {
  .user-btn--mobile {
    justify-content: center;
    display: flex;
  }
}

@media (max-width: 576px) {
  .user-btn--mobile {
    flex-direction: column;
  }
}

.user-btn .btn-primary, .user-btn .btn-secondary {
  min-width: auto;
}

.nav__list {
  align-items: center;
  display: flex;
}

@media (max-width: 992px) {
  .nav__list {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 15px;
  }
}

.nav__item:not(:last-child) {
  margin-right: 32px;
}

@media (max-width: 1246px) {
  .nav__item:not(:last-child) {
    margin-right: 15px;
  }
}

@media (max-width: 992px) {
  .nav__item:not(:last-child) {
    margin-right: 0;
  }
}

.nav__link {
  color: var(--color-dark);
  text-wrap: nowrap;
  border-radius: 5px;
  line-height: normal;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: box-shadow .3s, -webkit-text-decoration-color .3s, text-decoration-color .3s, opacity .3s, text-shadow .3s;
  display: block;
}

@media (max-width: 992px) {
  .nav__link {
    text-align: center;
    padding: 20px 0;
    font-size: 20px;
  }
}

.nav__link.true {
  background-color: var(--color-white);
  border-radius: 32px;
  padding: 16px 24px;
}

@media (max-width: 992px) {
  .nav__link.true {
    font-size: 20px;
    font-weight: var(--fw-700);
    background-color: #0000;
    border-radius: 0;
    padding: 20px 0;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .nav__link.true {
    padding: 7px 0;
    font-size: 16px;
  }
}

.btn-primary {
  max-width: 229px;
  width: 100%;
  background-color: var(--color-dark);
  text-align: center;
  text-wrap: nowrap;
  line-height: 1;
  transition: box-shadow .3s, background-color .3s, color .3s, opacity .3s;
  display: inline-block;
  color: var(--color-white) !important;
  border-radius: 24px !important;
  margin: 0 !important;
  padding: 16px 24px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.btn-primary--reverse {
  background-color: var(--color-white);
  color: var(--color-dark) !important;
}

.btn-secondary {
  max-width: 229px;
  width: 100%;
  text-align: center;
  text-wrap: nowrap;
  color: var(--color-dark);
  border-radius: 24px;
  padding: 16px 24px;
  font-weight: 600;
  line-height: 1;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: box-shadow .3s, background-color .3s, opacity .3s;
  display: inline-block;
}

.card {
  height: 100%;
  background-color: var(--color-white);
  text-align: left;
  border-radius: 24px;
  padding: 24px;
}

.card__box {
  z-index: 1;
  position: relative;
}

.card img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

@media (max-width: 576px) {
  .card img {
    height: 250px;
  }
}

.card h2, .card h3, .card h4, .card h5, .card h6 {
  font-size: 24px;
  font-weight: var(--fw-600);
  color: var(--color-dark);
  margin-bottom: 8px;
  line-height: normal;
}

.card p:not(:last-child) {
  margin-bottom: 24px;
}

.card--blog .card__box:before {
  content: "";
  width: 48px;
  height: 48px;
  background-color: var(--color-blue-800);
  background-image: url("../images/icons/arrow-diagonal.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  bottom: 24px;
  right: 24px;
}

@media (max-width: 576px) {
  .card--blog .card__box:before {
    bottom: 12px;
    right: 12px;
  }
}

.card--blog img {
  margin-bottom: 24px;
}

.card--product .card__price {
  min-width: 143px;
  text-align: center;
  background-color: var(--color-white);
  font-weight: var(--fw-600);
  color: var(--color-dark);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  display: flex;
  position: absolute;
  bottom: 24px;
  right: 24px;
}

@media (max-width: 576px) {
  .card--product .card__price {
    min-width: auto;
    font-size: 12px;
    bottom: 12px;
    right: 12px;
  }
}

.card--product img {
  margin-bottom: 48px;
}

.userinfo {
  align-items: center;
  display: flex;
}

.userinfo__date, .userinfo__name {
  z-index: 1;
  color: var(--color-grey-700);
  padding-left: 28px;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.userinfo__date:before, .userinfo__name:before {
  content: "";
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.userinfo__date {
  margin-right: 26px;
}

.userinfo__date:before {
  background-image: url("../images/icons/calendar.svg");
}

.userinfo__name:before {
  background-image: url("../images/icons/author.svg");
}

.reviews--padding {
  padding: 176px 0;
}

@media (max-width: 1246px) {
  .reviews--padding {
    padding: 80px 0;
  }

  .reviews {
    text-align: center;
  }
}

.reviews__list {
  column-gap: 24px;
  display: flex;
}

@media (max-width: 1246px) {
  .reviews__list {
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 24px;
  }
}

.reviews__item {
  max-width: 389px;
  width: 100%;
}

.reviews__card {
  height: 100%;
  background-color: var(--color-white);
  text-align: left;
  border-radius: 24px;
  padding: 24px;
}

@media (max-width: 576px) {
  .reviews__card {
    text-align: center;
  }
}

.reviews__userbox {
  margin-bottom: 24px;
  display: flex;
}

@media (max-width: 576px) {
  .reviews__userbox {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
}

.reviews__userpic {
  object-fit: cover;
  border-radius: 50%;
  margin-right: 24px;
}

@media (max-width: 576px) {
  .reviews__userpic {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.reviews__userinfo:before {
  content: "";
  width: 136px;
  height: 24px;
  background-image: url("../images/icons/stars.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 8px;
  display: block;
}

@media (max-width: 576px) {
  .reviews__userinfo:before {
    margin-left: auto;
    margin-right: auto;
  }
}

.reviews__username {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  display: -webkit-box;
  overflow: hidden;
}

.reviews__userjob {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.process {
  z-index: 1;
  text-align: left;
  padding-left: 78px;
  position: relative;
}

@media (max-width: 1246px) {
  .process {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    display: flex;
  }
}

.process:before, .process:after {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

@media (max-width: 1246px) {
  .process:before, .process:after {
    content: none;
  }
}

.process:before {
  width: 33px;
  height: 30px;
  background-image: url("../images/icons/arrow-down.svg");
  top: 0;
  left: 0;
}

.process:after {
  width: 30px;
  height: 832px;
  background-image: url("../images/icons/decor-line.svg");
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.process__item {
  background-color: var(--color-white);
  border-radius: 24px;
  justify-content: space-between;
  padding: 24px;
  display: flex;
}

.process__item:not(:last-child) {
  margin-bottom: 24px;
}

@media (max-width: 1246px) {
  .process__item {
    flex-direction: column;
    align-items: center;
  }
}

.process__info {
  max-width: 494px;
  width: 100%;
}

@media (max-width: 1246px) {
  .process__info {
    margin-bottom: 40px;
  }
}

.process__title {
  font-size: 24px;
}

.process__img {
  object-fit: cover;
  border-radius: 10px;
}

.advantages {
  color: var(--color-grey-850);
}

.advantages__inner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  padding: 128px 48px 48px;
}

.advantages__inner--overlay {
  z-index: 1;
  position: relative;
}

.advantages__inner--overlay:before {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #000000bf;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1246px) {
  .advantages__inner {
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 48px;
  }
}

@media (max-width: 992px) {
  .advantages__inner {
    padding: 48px 15px;
  }
}

.advantages__top {
  max-width: 548px;
  margin-bottom: 88px;
}

@media (max-width: 1246px) {
  .advantages__top {
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}

.advantages__title {
  color: var(--color-white);
  margin-bottom: 16px;
}

.advantages p:not(:last-child) {
  margin-bottom: 20px;
}

.advantages__list {
  display: flex;
}

@media (max-width: 1246px) {
  .advantages__list {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 24px;
  }
}

.advantages__item {
  z-index: 1;
  max-width: 368px;
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #fff3;
  border-radius: 24px;
  padding: 16px 16px 16px 112px;
  position: relative;
}

@media (max-width: 576px) {
  .advantages__item {
    padding-top: 116px;
    padding-left: 16px;
  }
}

.advantages__item:not(:last-child) {
  margin-right: 8px;
}

@media (max-width: 1246px) {
  .advantages__item:not(:last-child) {
    margin-right: 0;
  }
}

.advantages__item:before {
  content: "";
  width: 80px;
  height: 80px;
  background-color: var(--color-grey-800);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 16px;
}

.advantages__item--time:before {
  background-image: url("../images/icons/time.svg");
}

.advantages__item--like:before {
  background-image: url("../images/icons/like.svg");
}

.advantages__item--star:before {
  background-image: url("../images/icons/star.svg");
}

.advantages__item--check:before {
  background-image: url("../images/icons/check.svg");
}

.advantages__item--people:before {
  background-image: url("../images/icons/people.svg");
}

.advantages__item--technology:before {
  background-image: url("../images/icons/technology.svg");
}

.advantages__item--heart:before {
  background-image: url("../images/icons/heart.svg");
}

.advantages__subtitle {
  font-size: 20px;
  font-weight: var(--fw-600);
  color: var(--color-white);
  margin-bottom: 8px;
  line-height: normal;
}

@media (max-width: 576px) {
  .advantages__subtitle {
    font-size: 18px;
  }
}

.main--blog-article {
  padding-top: calc(var(--header-height)  + 128px);
  padding-bottom: 88px;
}

.blog-article {
  background-color: var(--color-white);
  border-radius: 32px;
  padding: 48px;
}

@media (max-width: 1246px) {
  .blog-article {
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;
  }
}

.blog-article__top {
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.blog-article__date, .blog-article__author {
  z-index: 1;
  padding-left: 28px;
  position: relative;
}

.blog-article__date:before, .blog-article__author:before {
  content: "";
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-article__date {
  margin-right: 24px;
}

.blog-article__date:before {
  background-image: url("../images/icons/calendar.svg");
}

.blog-article__author:before {
  background-image: url("../images/icons/author.svg");
}

.blog-article h2 {
  font-size: 40px;
  font-weight: var(--fw-700);
  color: var(--color-dark);
  margin-bottom: 24px;
  line-height: normal;
}

@media (max-width: 1246px) {
  .blog-article h2 {
    font-size: 30px;
  }
}

@media (max-width: 992px) {
  .blog-article h2 {
    font-size: 24px;
  }
}

.blog-article h3 {
  margin-bottom: 1.5em;
}

.blog-article p:not(:last-child) {
  margin-bottom: 1.4em;
}

.blog-article ol {
  margin-bottom: 1.5em;
  list-style-type: decimal;
}

.blog-article li {
  list-style: inherit;
  list-style-position: inside;
}

.blog-article li :first-child {
  display: inline;
}

.blog-article li:not(:last-child) {
  margin-bottom: 1.6em;
}

.blog-article li p {
  text-indent: 10px;
}

.blog-article li h3 {
  margin-bottom: 0;
}

.blog-article li img {
  margin-bottom: 88px;
}

@media (max-width: 992px) {
  .blog-article li img {
    margin-bottom: 40px;
  }
}

.blog-article img {
  object-fit: cover;
  border-radius: 24px;
}

.blog-article img:not(:last-child) {
  margin-bottom: 88px;
}

@media (max-width: 768px) {
  .blog-article img {
    height: 250px;
  }
}

@media (max-width: 576px) {
  .blog-article img {
    height: 200px;
  }
}

.blog-article a {
  color: var(--color-blue);
  text-decoration: underline;
}

.form {
  text-align: center;
}

.form__inner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  justify-content: center;
  padding: 88px 15px;
  display: flex;
}

@media (max-width: 1246px) {
  .form__inner {
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 576px) {
  .form__inner {
    padding: 60px 15px;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .form__inner {
    padding: 60px 15px;
  }
}

.form__box {
  max-width: 802px;
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #fff3;
  border-radius: 32px;
  padding: 32px;
}

@media (max-width: 576px) {
  .form__box {
    padding: 10px;
  }
}

.form__title {
  margin-bottom: 32px;
}

.form__label {
  margin-bottom: 16px;
  display: block;
}

.form__label:last-of-type {
  margin-bottom: 32px;
}

@media (max-width: 576px) {
  .form__label:last-of-type {
    margin-bottom: 20px;
  }
}

.form__input {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 16px;
  padding: 21px 16px;
  transition: box-shadow .3s;
  display: block;
}

.form__input::placeholder {
  color: var(--color-grey-700);
  transition: color .3s;
}

.form__input:focus {
  box-shadow: 0 0 0 3px var(--color-blue-900);
}

.form__input:hover::placeholder {
  color: var(--color-grey-900);
}

.form__input--big {
  resize: vertical;
  min-height: 156px;
  max-height: 300px;
}

.hero-section .container {
  padding: 0;
}

.hero-section__inner {
  border-radius: 32px;
  align-items: flex-start;
}

@media (max-width: 1246px) {
  .hero-section__inner {
    border-radius: 0;
    flex-direction: column;
    align-items: center;
  }
}

.hero-section__content {
  max-width: 560px;
  width: 100%;
  margin-right: 48px;
  padding-top: 104px;
}

@media (max-width: 1246px) {
  .hero-section__content {
    max-width: none;
    margin-right: 0;
    padding-top: 50px;
  }
}

.hero-section__title {
  margin-bottom: 24px;
}

@media (max-width: 1246px) {
  .hero-section__text {
    margin-bottom: 20px;
  }
}

.hero-section__img {
  object-fit: cover;
  border-radius: 24px;
}

@media (max-width: 1246px) {
  .hero-section__img {
    width: 100%;
    height: 400px;
  }
}

.services-section {
  text-align: center;
}

.services-section--padding {
  padding-bottom: 176px;
}

@media (max-width: 1246px) {
  .services-section--padding {
    padding-bottom: 80px;
  }
}

.services-section .slogan {
  margin-bottom: 24px;
}

.services-section__title {
  margin-bottom: 8px;
}

.services-section__text {
  max-width: 656px;
  margin: 0 auto 64px;
}

.services-section__list {
  justify-content: space-between;
  column-gap: 24px;
  display: flex;
}

@media (max-width: 1246px) {
  .services-section__list {
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 24px;
  }
}

.services-section__item {
  max-width: 390px;
  width: 100%;
}

@media (max-width: 1246px) {
  .services-section__item {
    max-width: 500px;
  }
}

.burger {
  z-index: 15;
  width: 44px;
  height: 44px;
  color: var(--color-dark);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  transition: box-shadow .3s, background-color .2s;
  display: none;
  position: relative;
}

@media (max-width: 992px) {
  .burger {
    display: flex;
  }
}

.burger:before, .burger:after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: currentColor;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.burger:before {
  transition: top .15s ease-in .15s, transform .15s ease-in;
  top: 11px;
}

.burger:after {
  transition: bottom .15s ease-in .15s, transform .15s ease-in;
  bottom: 11px;
}

.burger__line {
  width: 30px;
  height: 2px;
  background-color: currentColor;
  transition: transform .15s ease-in;
}

.burger.active:before {
  transition-delay: 0s, .15s;
  top: 21px;
  transform: translateX(-50%)rotate(45deg);
}

.burger.active:after {
  transition-delay: 0s, .15s;
  bottom: 21px;
  transform: translateX(-50%)rotate(-45deg);
}

.burger.active .burger__line {
  transform: scaleX(0);
}

.footer {
  color: var(--color-dark);
  text-align: center;
  padding: 88px 0 24px;
}

@media (max-width: 992px) {
  .footer {
    padding-top: 40px;
  }
}

.footer__container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 992px) {
  .footer__container {
    flex-direction: column;
    row-gap: 20px;
  }
}

.footer__list {
  display: flex;
}

@media (max-width: 992px) {
  .footer__list {
    flex-wrap: wrap;
    align-items: center;
  }
}

.footer__item:not(:last-child) {
  margin-right: 24px;
}

.footer__link {
  border-radius: 5px;
  transition: box-shadow .3s, text-shadow .3s, opacity .3s;
}

.policy__content:not(:last-child) {
  margin-bottom: 2em;
}

.policy h2 {
  font-weight: var(--fw-700);
}

.policy h2:not(:last-child), .policy p:not(:last-child), .policy ul:not(:last-child) {
  margin-bottom: 2em;
}

.policy li {
  padding-left: 5px;
}

.policy li::marker {
  content: "-";
}

.policy a {
  color: var(--color-blue-900);
  text-decoration: underline;
}

.policy b, .policy strong {
  font-weight: var(--fw-600);
}

@media (max-width: 1246px) {
  .hero-home .container {
    padding: 0;
  }
}

.hero-home__content {
  max-width: 656px;
  width: 100%;
  text-align: center;
}

.hero-home__inner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
}

.hero-home__title {
  color: var(--color-white);
  margin-bottom: 16px;
  font-size: 56px;
  line-height: normal;
}

@media (max-width: 992px) {
  .hero-home__title {
    font-size: 40px;
  }
}

@media (max-width: 576px) {
  .hero-home__title {
    font-size: 30px;
  }
}

.hero-home__text {
  color: var(--color-grey-850);
  margin-bottom: 48px;
  font-size: 20px;
}

@media (max-width: 576px) {
  .hero-home__text {
    font-size: 16px;
  }
}

@media (max-width: 1246px) {
  .mission {
    text-align: center;
  }
}

.mission__inner {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1246px) {
  .mission__inner {
    flex-direction: column;
    align-items: center;
  }
}

.mission__info {
  max-width: 584px;
  width: 100%;
}

@media (max-width: 1246px) {
  .mission__info {
    margin-bottom: 60px;
  }
}

.mission__info p {
  margin-bottom: 48px;
}

@media (max-width: 1246px) {
  .mission__info p {
    margin-bottom: 30px;
  }
}

.mission__list {
  max-width: 584px;
  width: 100%;
  text-align: left;
}

@media (max-width: 1246px) {
  .mission__list {
    max-width: none;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 25px;
    display: flex;
  }
}

.mission__item {
  z-index: 1;
  background-color: var(--color-white);
  border-radius: 24px;
  padding: 16px 24px 16px 136px;
  position: relative;
}

.mission__item:not(:last-child) {
  margin-bottom: 8px;
}

.mission__item:before {
  content: "";
  width: 80px;
  height: 80px;
  background-color: var(--color-grey-800);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 24px;
}

.mission__item--service:before {
  background-image: url("../images/icons/check.svg");
}

.mission__item--technology:before {
  background-image: url("../images/icons/technology.svg");
}

.mission__item--expertise:before {
  background-image: url("../images/icons/people.svg");
}

@media (max-width: 1246px) {
  .mission__item {
    max-width: 500px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .mission__item {
    padding-top: 116px;
    padding-left: 24px;
  }
}

.services {
  text-align: center;
}

.services__title, .services .slogan {
  text-align: left;
}

@media (max-width: 992px) {
  .services__title, .services .slogan {
    text-align: center;
  }
}

.services__list {
  text-align: left;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 64px;
  display: grid;
}

@media (max-width: 992px) {
  .services__list {
    grid-template-columns: auto;
    justify-content: center;
    margin-bottom: 30px;
  }
}

.services__item {
  max-width: 596px;
}

.how {
  padding-bottom: 176px;
}

@media (max-width: 1246px) {
  .how {
    text-align: center;
    padding-bottom: 80px;
  }
}

.blog-preview {
  text-align: center;
  padding: 176px 0;
}

@media (max-width: 1246px) {
  .blog-preview {
    padding: 80px 0;
  }
}

.blog-preview__title, .blog-preview .slogan {
  text-align: left;
}

.blog-preview__list {
  justify-content: space-between;
  column-gap: 24px;
  margin-bottom: 64px;
  display: flex;
}

@media (max-width: 1246px) {
  .blog-preview__list {
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 24px;
  }
}

@media (max-width: 992px) {
  .blog-preview__list {
    margin-bottom: 30px;
  }
}

.blog-preview__list a {
  height: 100%;
  display: block;
}

.blog-preview__item {
  max-width: 390px;
  width: 100%;
}

@media (max-width: 1246px) {
  .blog-preview__item {
    max-width: 500px;
  }
}

.blog-preview .userinfo {
  margin-bottom: 24px;
}

.history__info {
  max-width: 659px;
  text-align: center;
  margin: 0 auto 128px;
}

@media (max-width: 1246px) {
  .history__info {
    margin-bottom: 50px;
  }

  .history__list {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
    display: flex;
  }
}

.history__title {
  margin-bottom: 24px;
}

.history__item {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.history__item:before {
  content: "";
  width: 30px;
  height: 30px;
  background-color: var(--color-dark);
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1246px) {
  .history__item:before {
    content: none;
  }
}

.history__item:not(:last-child) {
  padding-bottom: 88px;
}

@media (max-width: 1246px) {
  .history__item:not(:last-child) {
    padding-bottom: 50px;
  }
}

.history__item:not(:last-child):after {
  content: "";
  width: 4px;
  height: 100%;
  background-color: var(--color-dark);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1246px) {
  .history__item:not(:last-child):after {
    content: none;
  }
}

.history__item:nth-child(2) {
  text-align: right;
}

@media (max-width: 1246px) {
  .history__item:nth-child(2) {
    text-align: left;
  }
}

.history__item:nth-child(2) .history__img {
  order: 1;
}

@media (max-width: 1246px) {
  .history__item:nth-child(2) .history__img {
    order: 0;
  }

  .history__item {
    width: calc(50% - 20px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .history__item {
    width: 100%;
  }
}

.history__img {
  object-fit: cover;
  border-radius: 24px;
}

@media (max-width: 1246px) {
  .history__img {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
  }
}

.history__item-info {
  max-width: 517px;
  width: 100%;
}

@media (max-width: 1246px) {
  .history__item-info {
    max-width: none;
  }
}

.history__subtitle {
  color: var(--color-dark);
  margin-bottom: 16px;
  font-size: 40px;
  line-height: normal;
}

@media (max-width: 1246px) {
  .history__subtitle {
    font-size: 25px;
  }
}

.team {
  padding-bottom: 176px;
}

@media (max-width: 1246px) {
  .team {
    padding-bottom: 80px;
  }
}

.options__item {
  background-color: var(--color-white);
  border-radius: 24px;
  justify-content: space-between;
  padding: 24px;
  display: flex;
}

@media (max-width: 1246px) {
  .options__item {
    flex-direction: column;
    align-items: center;
  }
}

.options__item:not(:last-child) {
  margin-bottom: 24px;
}

.options__img {
  object-fit: cover;
  border-radius: 16px;
}

@media (max-width: 1246px) {
  .options__img {
    height: 250px;
    margin-bottom: 20px;
  }
}

.options__desc {
  max-width: 560px;
  width: 100%;
}

@media (max-width: 1246px) {
  .options {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.stories {
  padding: 176px 0 106px;
}

@media (max-width: 1246px) {
  .stories {
    padding: 80px 0;
  }
}

.stories__slide {
  max-width: var(--container-width);
  width: 100%;
  justify-content: space-between;
  padding: 0 15px;
  display: flex;
}

@media (max-width: 992px) {
  .stories__slide {
    flex-direction: column;
    align-items: center;
  }
}

.stories__box {
  counter-increment: number;
  margin-right: 48px;
}

.stories__box:before {
  content: "0" counter(number);
  color: var(--color-dark);
  margin-bottom: 16px;
  font-size: 40px;
  line-height: normal;
  display: block;
}

@media (max-width: 992px) {
  .stories__box:before {
    font-size: 30px;
  }
}

@media (max-width: 1246px) {
  .stories__box {
    margin-bottom: 30px;
  }
}

.stories__info {
  margin-bottom: 32px;
}

@media (max-width: 1246px) {
  .stories__info {
    margin-bottom: 20px;
  }
}

.stories__solution-title {
  font-size: 24px;
  line-height: auto;
  color: var(--color-dark);
}

.stories__img {
  object-fit: cover;
  border-radius: 24px;
}

@media (max-width: 1246px) {
  .stories__img {
    height: 350px;
  }
}

.stories .stories-swiper {
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;
}

.stories .swiper-wrapper {
  counter-reset: number;
}

.stories .swiper-pagination {
  background-color: var(--color-white);
  border-radius: 16px;
  justify-content: center;
  padding: 8px 16px;
  display: flex;
  position: absolute;
  transform: translateX(-50%);
  width: auto !important;
  bottom: 0 !important;
  left: 50% !important;
}

@media (max-width: 1246px) {
  .stories .swiper-pagination {
    transform: translateX(-50%);
    bottom: 16px !important;
    left: 50% !important;
    right: auto !important;
  }
}

.stories .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: var(--color-grey-900);
  transition: background-color .3s;
  margin: 0 !important;
}

.stories .swiper-pagination-bullet:not(:last-child) {
  margin-right: 16px !important;
}

.stories .swiper-pagination-bullet-active {
  background-color: var(--color-dark) !important;
}

.stories .swiper-pagination-bullet:hover {
  background-color: var(--color-blue-900);
}

.stories .swiper-button-prev, .stories .swiper-button-next {
  width: 48px;
  height: 48px;
  background-color: var(--color-white);
  border-radius: 50%;
  font-size: 0;
  transition: box-shadow .3s, background-color .3s, opacity .3s;
  top: auto;
  bottom: 0;
}

.stories .swiper-button-prev:after, .stories .swiper-button-next:after {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
}

.stories .swiper-button-prev {
  left: 24px;
}

.stories .swiper-button-prev:after {
  background-image: url("../images/icons/arrow-left.svg");
}

.stories .swiper-button-next {
  right: 24px;
}

.stories .swiper-button-next:after {
  background-image: url("../images/icons/arrow-right.svg");
}

.custom {
  color: var(--color-grey-850);
}

.custom__inner {
  min-height: 652px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  align-items: stretch;
  padding: 128px 48px 48px;
}

.custom__content {
  max-width: 548px;
}

.custom__title {
  color: var(--color-white);
  margin-bottom: 16px;
}

.custom__text {
  margin-bottom: 48px;
}

.step {
  padding-bottom: 176px;
}

@media (max-width: 1246px) {
  .step {
    padding-bottom: 80px;
  }
}

.customization {
  padding-bottom: 176px;
}

@media (max-width: 1246px) {
  .customization {
    padding-bottom: 80px;
  }
}

@media (max-width: 576px) {
  .benefits {
    text-align: center;
  }
}

.benefits__list {
  justify-content: space-between;
  column-gap: 24px;
  display: flex;
}

@media (max-width: 1246px) {
  .benefits__list {
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 24px;
  }
}

.benefits__item {
  max-width: 390px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 24px;
  padding: 24px;
}

.benefits__item:before {
  content: "";
  width: 80px;
  height: 80px;
  background-color: var(--color-grey-800);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: 0 auto 32px;
  display: block;
}

.benefits__item--check:before {
  background-image: url("../images/icons/check.svg");
}

.benefits__item--star:before {
  background-image: url("../images/icons/star.svg");
}

.benefits__item--technology:before {
  background-image: url("../images/icons/technology.svg");
}

.faq {
  padding: 176px 0;
}

@media (max-width: 1246px) {
  .faq {
    padding: 80px 0;
  }
}

.faq__item {
  background-color: var(--color-white);
  border-radius: 32px;
}

.faq__item:not(:last-child) {
  margin-bottom: 8px;
}

.faq__spoiler {
  z-index: 1;
  padding-left: 24px;
  position: relative;
}

.faq__spoiler:before {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/arrow-spoiler.svg");
  background-position: center;
  background-repeat: no-repeat;
  transition: transform .3s;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%)rotate(180deg);
}

.faq__spoiler[open]:before {
  transform: translateY(-50%)rotate(0);
}

.faq__subtitle {
  cursor: pointer;
  margin-bottom: 0;
  padding: 24px 80px 24px 0;
}

@media (max-width: 992px) {
  .faq__subtitle {
    font-size: 18px;
  }
}

.faq__answer {
  padding-bottom: 24px;
  padding-right: 80px;
}

.blog {
  padding-top: 128px;
}

@media (max-width: 1246px) {
  .blog {
    padding-top: 60px;
  }
}

@media (max-width: 992px) {
  .blog {
    text-align: center;
  }
}

.blog__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

@media (max-width: 992px) {
  .blog__list {
    grid-template-columns: auto;
    justify-content: center;
  }
}

.blog .card {
  max-width: 596px;
}

.blog .userinfo {
  margin-bottom: 24px;
}

/*# sourceMappingURL=main.css.map */
