@use "../utils/functions/" as*;
@use "../utils/mixins/" as*;

.title {
  margin-bottom: 64px;
  font-size: 48px;
  font-weight: var(--fw-600);
  line-height: normal;
  color: var(--color-dark);

  @media (max-width: 992px) {
    margin-bottom: 30px;
  }

  @media (max-width: 992px) {
    font-size: 30px;
  }

  @media (max-width: 576px) {
    font-size: 25px;
  }
}

.subtitle {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: var(--fw-600);
  line-height: normal;
  color: var(--color-dark);

  @media (max-width: 576px) {
    font-size: 20px;
  }
}

.slogan {
  display: block;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: var(--fw-400);
  line-height: normal;
  color: var(--color-grey-900);

  @media (max-width: 576px) {
    font-size: 18px;
  }
}