.process {
  position: relative;
  z-index: 1;
  padding-left: 78px;
  text-align: left;

  @media (max-width: 1246px) {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  &::before,
  &::after {
    content: "";
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 1246px) {
      content: none;
    }
  }

  &::before {
    top: 0;
    left: 0;
    width: 33px;
    height: 30px;
    background-image: url("../images/icons/arrow-down.svg");
  }


  &::after {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 832px;
    background-image: url("../images/icons/decor-line.svg");
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 24px;
    background-color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @media (max-width: 1246px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info {
    max-width: 494px;
    width: 100%;


    @media (max-width: 1246px) {
      margin-bottom: 40px;
    }
  }

  &__title {
    font-size: 24px;
  }


  &__img {
    border-radius: 10px;
    object-fit: cover;
  }
}