.main--blog-article {
  padding-top: calc(var(--header-height) + 128px);
  padding-bottom: 88px;
}

.blog-article {
  padding: 48px;
  border-radius: 32px;
  background-color: var(--color-white);

  @media (max-width: 1246px) {
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
  }

  &__top {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
  }

  &__date,
  &__author {
    position: relative;
    z-index: 1;
    padding-left: 28px;


    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }


  &__date {
    margin-right: 24px;

    &::before {
      background-image: url("../images/icons/calendar.svg");
    }
  }

  &__author {
    &::before {
      background-image: url("../images/icons/author.svg");
    }
  }

  h2 {
    margin-bottom: 24px;
    font-size: 40px;
    font-weight: var(--fw-700);
    line-height: normal;
    color: var(--color-dark);

    @media (max-width: 1246px) {
      font-size: 30px;
    }

    @media (max-width: 992px) {
      font-size: 24px;
    }
  }

  h3 {
    margin-bottom: 1.5em;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1.4em;
    }
  }

  ol {
    list-style-type: decimal;
    margin-bottom: 1.5em;
  }

  li {
    list-style: inherit;
    list-style-position: inside;

    *:first-child {
      display: inline;
    }

    &:not(:last-child) {
      margin-bottom: 1.6em;
    }

    p {
      text-indent: 10px;
    }

    h3 {
      margin-bottom: 0;
    }

    img {
      margin-bottom: 88px;

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }
    }
  }

  img {
    object-fit: cover;
    border-radius: 24px;

    &:not(:last-child) {
      margin-bottom: 88px;
    }

    @media (max-width: 768px) {
      height: 250px;
    }

    @media (max-width: 576px) {
      height: 200px;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-blue);
  }
}