.mission {

  @media (max-width: 1246px) {
    text-align: center;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1246px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info {
    max-width: 584px;
    width: 100%;

    @media (max-width: 1246px) {
      margin-bottom: 60px;
    }

    p {
      margin-bottom: 48px;

      @media (max-width: 1246px) {
        margin-bottom: 30px;
      }
    }
  }

  &__list {
    max-width: 584px;
    width: 100%;
    text-align: left;

    @media (max-width: 1246px) {
      max-width: none;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 25px;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    padding: 16px 24px;
    padding-left: 136px;
    border-radius: 24px;
    background-color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 24px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: var(--color-grey-800);
      background-repeat: no-repeat;
      background-position: center;
    }

    &--service {
      &::before {
        background-image: url('../images/icons/check.svg');
      }
    }

    &--technology {
      &::before {
        background-image: url('../images/icons/technology.svg');
      }
    }

    &--expertise {
      &::before {
        background-image: url('../images/icons/people.svg');
      }
    }

    @media (max-width: 1246px) {
      max-width: 500px;
      width: 100%;
    }

    @media (max-width: 576px) {
      padding-left: 24px;
      padding-top: 116px;
    }

  }
}