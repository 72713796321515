.advantages {
  color: var(--color-grey-850);

  &__inner {
    padding: 128px 48px 48px;
    border-radius: 24px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &--overlay {
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
      }
    }

    @media (max-width: 1246px) {
      padding-top: 48px;
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 0;
    }

    @media (max-width: 992px) {
      padding: 48px 15px;
    }
  }

  &__top {
    max-width: 548px;
    margin-bottom: 88px;

    @media (max-width: 1246px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-bottom: 16px;
    color: var(--color-white);
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__list {
    display: flex;

    @media (max-width: 1246px) {
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 24px
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    max-width: 368px;
    width: 100%;
    padding: 16px;
    padding-left: 112px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);

    @media (max-width: 576px) {
      padding-left: 16px;
      padding-top: 116px;
    }

    &:not(:last-child) {
      margin-right: 8px;

      @media (max-width: 1246px) {
        margin-right: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 16px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: var(--color-grey-800);
      background-position: center;
      background-repeat: no-repeat;
    }

    &--time {
      &::before {
        background-image: url('../images/icons/time.svg');
      }
    }

    &--like {
      &::before {
        background-image: url('../images/icons/like.svg');
      }
    }

    &--star {
      &::before {
        background-image: url('../images/icons/star.svg');
      }
    }

    &--check {
      &::before {
        background-image: url('../images/icons/check.svg');
      }
    }

    &--people {
      &::before {
        background-image: url('../images/icons/people.svg');
      }
    }

    &--technology {
      &::before {
        background-image: url('../images/icons/technology.svg');
      }
    }

    &--heart {
      &::before {
        background-image: url('../images/icons/heart.svg');
      }
    }
  }

  &__subtitle {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: var(--fw-600);
    line-height: normal;
    color: var(--color-white);

    @media (max-width: 576px) {
      font-size: 18px;
    }
  }
}