@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 24px 0;
  transition: transform 0.15s, padding 0.15s, background-color 0.3s;

  &::before {
    content: "";
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: var(--color-grey-800);
  }

  @media (max-width: 992px) {
    padding: 12px 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
  }

  &__logo {
    align-self: flex-start;
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  &__inner {
    position: relative;
    z-index: 11;

    @media (max-width: 992px) {
      position: fixed;
      z-index: 10;
      top: var((--header-height));
      right: 0;
      width: 50%;
      min-width: 400px;
      height: calc(100% - var(--header-height));
      background-color: var(--color-white);
      transform: translateX(100%);
      transition: transform 0.3s;

      &.active {
        transform: translateX(0);
      }
    }

    @media (max-width: 576px) {
      width: 100%;
      min-width: 100%;
    }
  }
}