@use "../utils/functions/" as*;
@use "../utils/mixins/" as*;

.footer {
  padding: 88px 0 24px;
  color: var(--color-dark);
  text-align: center;

  @media (max-width: 992px) {
    padding-top: 40px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 992px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &__list {
    display: flex;

    @media (max-width: 992px) {
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__link {
    border-radius: 5px;
    transition: box-shadow 0.3s, text-shadow 0.3s, opacity 0.3s;
  }
}