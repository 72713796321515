/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as*;
@use "../utils/mixins" as*;


.btn-primary {
  display: inline-block;
  max-width: 229px;
  width: 100%;
  padding: 16px 24px !important;
  border-radius: 24px !important;
  background-color: var(--color-dark);
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1;
  color: var(--color-white) !important;
  text-align: center;
  text-wrap: nowrap;
  transition: box-shadow 0.3s, background-color 0.3s, color 0.3s, opacity 0.3s;
  margin: 0 !important;

  &--reverse {
    background-color: var(--color-white);
    color: var(--color-dark) !important;
  }
}

.btn-secondary {
  display: inline-block;
  max-width: 229px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 24px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-wrap: nowrap;
  color: var(--color-dark);
  text-decoration: underline transparent;
  transition: box-shadow 0.3s, background-color 0.3s, opacity 0.3s;
}