.services-section {
  text-align: center;

  &--padding {
    padding-bottom: 176px;


    @media (max-width: 1246px) {
      padding-bottom: 80px;
    }
  }

  .slogan {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    max-width: 656px;
    margin: 0 auto 64px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;

    @media (max-width: 1246px) {
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 24px;
    }
  }

  &__item {
    max-width: 390px;
    width: 100%;

    @media (max-width: 1246px) {
      max-width: 500px;
    }
  }
}