.form {
  text-align: center;

  &__inner {
    padding: 88px 15px;
    display: flex;
    justify-content: center;
    border-radius: 24px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 1246px) {
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 0;
    }

    @media (max-width: 576px) {
      padding: 60px 15px;
    }

    @media (max-width: 992px) and (orientation: landscape) {
      padding: 60px 15px;
    }
  }

  &__box {
    max-width: 802px;
    width: 100%;
    padding: 32px;
    border-radius: 32px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);

    @media (max-width: 576px) {
      padding: 10px;
    }
  }

  &__title {
    margin-bottom: 32px;
  }

  &__label {
    display: block;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 32px;

      @media (max-width: 576px) {
        margin-bottom: 20px;
      }
    }
  }

  &__input {
    display: block;
    width: 100%;
    padding: 21px 16px;
    border-radius: 16px;
    background-color: var(--color-white);
    transition: box-shadow 0.3s;

    &::placeholder {
      color: var(--color-grey-700);
      transition: color 0.3s;
    }

    &:focus {
      box-shadow: 0 0 0 3px var(--color-blue-900);
    }

    &:hover {
      &::placeholder {
        color: var(--color-grey-900);
      }
    }


    &--big {
      resize: vertical;
      min-height: 156px;
      max-height: 300px;
    }
  }
}