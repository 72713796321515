.userinfo {
  display: flex;
  align-items: center;

  &__date,
  &__name {
    position: relative;
    z-index: 1;
    padding-left: 28px;
    font-size: 14px;
    line-height: normal;
    color: var(--color-grey-700);

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__date {
    margin-right: 26px;

    &::before {
      background-image: url("../images/icons/calendar.svg");
    }
  }

  &__name {
    &::before {
      background-image: url("../images/icons/author.svg");
    }
  }
}