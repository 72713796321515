.blog {
  padding-top: 128px;

  @media (max-width: 1246px) {
    padding-top: 60px;
  }

  @media (max-width: 992px) {
    text-align: center;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @media (max-width: 992px) {
      grid-template-columns: auto;
      justify-content: center;
    }
  }

  .card {
    max-width: 596px;
  }

  .userinfo {
    margin-bottom: 24px;
  }
}