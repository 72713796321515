/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1216px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color

  --color-white: #ffffff;

  --color-dark: #161616;

  --color-grey-900: #75717a;
  --color-grey-850: #c6c6c6;
  --color-grey-800: #eceef2;
  --color-grey-800-50: #eceef27c;
  --color-grey-700: #bab8bd;


  --color-blue-900: #51647d;
  --color-blue-800: #d5dfed;

}