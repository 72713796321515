.team {
  padding-bottom: 176px;

  @media (max-width: 1246px) {
    padding-bottom: 80px;
  }
}

.options {



  &__item {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-radius: 24px;
    background-color: var(--color-white);

    @media (max-width: 1246px) {
      flex-direction: column;
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__img {
    object-fit: cover;
    border-radius: 16px;

    @media (max-width: 1246px) {
      height: 250px;
      margin-bottom: 20px;
    }
  }

  &__desc {
    max-width: 560px;
    width: 100%;
  }

  @media (max-width: 1246px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}