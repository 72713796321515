.stories {
  padding: 176px 0 106px;

  @media (max-width: 1246px) {
    padding: 80px 0;
  }


  &__slide {
    display: flex;
    justify-content: space-between;
    max-width: var(--container-width);
    width: 100%;
    padding: 0 15px;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__box {
    margin-right: 48px;
    counter-increment: number;

    &::before {
      content: "0"counter(number);
      display: block;
      margin-bottom: 16px;
      font-size: 40px;
      line-height: normal;
      color: var(--color-dark);

      @media (max-width: 992px) {
        font-size: 30px;
      }
    }

    @media (max-width: 1246px) {
      margin-bottom: 30px;
    }
  }

  &__info {
    margin-bottom: 32px;

    @media (max-width: 1246px) {
      margin-bottom: 20px;
    }
  }

  &__solution-title {
    font-size: 24px;
    line-height: auto;
    color: var(--color-dark);
  }


  &__img {
    border-radius: 24px;
    object-fit: cover;

    @media (max-width: 1246px) {
      height: 350px;
    }
  }

  .stories-swiper {
    overflow: hidden;
    position: relative;
    max-width: var(--container-width);
    width: 100%;
    margin: 0 auto;
    padding-bottom: 70px;
  }

  .swiper-wrapper {
    counter-reset: number;
  }

  .swiper-pagination {
    position: absolute;
    transform: translateX(-50%);
    bottom: 0 !important;
    left: 50% !important;
    width: auto !important;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 16px;
    background-color: var(--color-white);

    @media (max-width: 1246px) {
      bottom: 16px !important;
      left: 50% !important;
      right: auto !important;
      transform: translateX(-50%);
    }
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 !important;
    background-color: var(--color-grey-900);
    transition: background-color 0.3s;

    &:not(:last-child) {
      margin-right: 16px !important;
    }

    &-active {
      background-color: var(--color-dark) !important;
    }

    &:hover {
      background-color: var(--color-blue-900);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--color-white);
    font-size: 0;
    transition: box-shadow 0.3s, background-color 0.3s, opacity 0.3s;

    &::after {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      font-size: 0;
    }
  }

  .swiper-button-prev {
    left: 24px;

    &::after {
      background-image: url("../images/icons/arrow-left.svg");
    }
  }

  .swiper-button-next {
    right: 24px;

    &::after {
      background-image: url("../images/icons/arrow-right.svg");
    }
  }
}