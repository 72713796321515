.blog-preview {
  padding: 176px 0;
  text-align: center;

  @media (max-width: 1246px) {
    padding: 80px 0;
  }

  &__title,
  .slogan {
    text-align: left;
  }

  &__list {
    margin-bottom: 64px;
    display: flex;
    justify-content: space-between;
    column-gap: 24px;

    @media (max-width: 1246px) {
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 24px;
    }

    @media (max-width: 992px) {
      margin-bottom: 30px;
    }

    a {
      display: block;
      height: 100%;
    }
  }

  &__item {
    max-width: 390px;
    width: 100%;

    @media (max-width: 1246px) {
      max-width: 500px;
    }
  }

  .userinfo {
    margin-bottom: 24px;
  }
}