.reviews {

  &--padding {
    padding: 176px 0;


    @media (max-width: 1246px) {
      padding: 80px 0;
    }
  }

  @media (max-width: 1246px) {
    text-align: center;
  }

  &__list {
    display: flex;
    column-gap: 24px;

    @media (max-width: 1246px) {
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 24px;
    }
  }

  &__item {
    max-width: 389px;
    width: 100%;
  }

  &__card {
    height: 100%;
    padding: 24px;
    border-radius: 24px;
    background-color: var(--color-white);
    text-align: left;

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  &__userbox {
    display: flex;
    margin-bottom: 24px;

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  &__userpic {
    margin-right: 24px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 576px) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__userinfo {
    &::before {
      content: "";
      display: block;
      width: 136px;
      height: 24px;
      margin-bottom: 8px;
      background-image: url("../images/icons/stars.svg");
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 576px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__username {
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  &__userjob {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}