.hero-section {

  .container {
    padding: 0;
  }

  &__inner {
    align-items: flex-start;
    border-radius: 32px;

    @media (max-width: 1246px) {
      flex-direction: column;
      align-items: center;
      border-radius: 0;
    }
  }

  &__content {
    max-width: 560px;
    width: 100%;
    padding-top: 104px;
    margin-right: 48px;

    @media (max-width: 1246px) {
      max-width: none;
      padding-top: 50px;
      margin-right: 0;
    }
  }

  &__title {
    margin-bottom: 24px;
  }


  &__text {
    @media (max-width: 1246px) {
      margin-bottom: 20px;
    }
  }

  &__img {
    object-fit: cover;
    border-radius: 24px;

    @media (max-width: 1246px) {
      width: 100%;
      height: 400px;
    }
  }
}