.user-btn {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &--desktop {
    position: relative;
    z-index: 11;

    @media (max-width: 992px) {
      display: none;
    }
  }

  &--mobile {
    display: none;
    padding: 0 15px;

    @media (max-width: 992px) {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  .btn-primary {
    min-width: auto;
  }

  .btn-secondary {
    min-width: auto;
  }
}