@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.hero-home {
  .container {
    @media (max-width: 1246px) {
      padding: 0;
    }
  }

  &__content {
    max-width: 656px;
    width: 100%;
    text-align: center;
  }

  &__inner {
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 56px;
    line-height: normal;
    color: var(--color-white);

    @media (max-width: 992px) {
      font-size: 40px;
    }

    @media (max-width: 576px) {
      font-size: 30px;
    }
  }

  &__text {
    margin-bottom: 48px;
    font-size: 20px;
    color: var(--color-grey-850);

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}