@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

body {
  &.lock {
    overflow: hidden;
  }
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-size: 16px;
  font-weight: var(--fw-500);
  font-style: normal;
  line-height: 1.5;
  color: var(--color-grey-900);
  background-color: var(--color-grey-800);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.background {
    background-color: var(--color-blue-400);
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--container-offset);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  padding-top: calc(var(--header-height) + 8px);

  @media (max-width: 992px) {
    padding-top: var(--header-height);
  }
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    background: var(--gradient);
    inset: 0;
  }
}

.background {
  background-color: var(--color-blue-100);

  &--dark {
    background-color: var(--color-blue-400);
  }
}

.logo {
  position: relative;
  z-index: 15;
  display: block;
  border-radius: 5px;
  transition: box-shadow 0.3s, opacity 0.3s;

  @media (max-width: 576px) {
    max-width: 200px;
  }
}

.hero-inner {
  min-height: 652px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 24px;
  background-color: var(--color-white);

  @media (max-width: 1246px) {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0;
  }

  @media (max-width: 992px) {
    min-height: 450px;
  }

  @media (max-width: 576px) {
    min-height: auto;
  }

  @media (max-width: 992px) and (orientation: landscape) {
    min-height: auto;
  }
}

.section {
  padding: 88px 0;

  @media (max-width: 1246px) {
    padding: 40px 0;
  }
}