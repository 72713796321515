@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.services {
  text-align: center;

  &__title,
  .slogan {
    text-align: left;

    @media (max-width: 992px) {
      text-align: center;
    }
  }

  &__list {
    margin-bottom: 64px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    text-align: left;

    @media (max-width: 992px) {
      margin-bottom: 30px;
      grid-template-columns: auto;
      justify-content: center;
    }
  }

  &__item {
    max-width: 596px;
  }
}